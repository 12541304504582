@import "../../../styles/variable.module.scss";
.createNft {
  .cardWrap {
    display: flex;
    flex-wrap: wrap;
    .card {
      width: calc(100% / 4 - 30px);
      margin-right: 30px;
      @include large_device {
        width: calc(100% / 3 - 20px);
        margin-right: 20px;
        margin-top: 32px;
      }
      @include medium_device {
        width: calc(100% / 2 - 20px);
        margin-right: 20px;
      }
      @include mobile_device {
        width: 100%;
        margin-right: 0;
        margin-bottom: 32px;
        margin-top: 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .title {
    margin-bottom: 48px;
    @include medium_device {
      font-size: 32px;
      margin-bottom: 32px;
    }
    @include mobile_device {
      font-size: 24px;
      margin-bottom: 32px;
      text-align: center;
    }
  }
  .cardImg {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 247px;
  }
  .cardBody {
    padding: 24px 0 0;
    text-align: center;
    .name {
      font-weight: 700;
      font-size: 18px;
      line-height: 32px;
      color: var(--desc-font);
      margin-bottom: 8px;
    }
    .text {
      font-size: 14px;
      line-height: 24px;
      color: $customFont;
    }
  }
}
[dir="rtl"] {
  .createNft {
    .cardWrap {
      .card {
        margin-left: 30px;
        margin-right: 0;
        @include large_device {
          margin-left: 20px;
        }
        @include mobile_device {
          margin-left: 0;
        }
        .cardBody {
          @include large_device {
            text-align: center;
          }
        }
        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
}

[data-theme=dark]{
  .createNft{
      .darkImgs{
        display: block !important;
      }
  }
}